<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight2 plr13 pb0">
      <div class="testList">
        <div class="f16 text111 fw700 mt20 mb20"
             v-if="historyTrainingList.length > 0">
          {{$t('my.testLogging')}}
        </div>
        <div v-if="historyTrainingList.length > 0">
          <div class="item mb10"
               v-for="history in historyTrainingList"
               :key="history.examCode">
            <div class="f14 flex justify-between align-center">
              <span class="text-cut flex1">{{ history.title }}</span>
              <span class="f17 textgreen"
                    v-if="history.grade >= 80">{{$t('my.through')}}</span>
              <span class="f17 textred"
                    v-if="history.grade < 80">{{$t('my.unqualified')}}</span>
              <!-- <span class="f12 text999 pointer more ml10"
                >查看更多<i class="el-icon-arrow-right pl5 f14"></i
              ></span> -->
            </div>
            <div class="text999 f12">
              {{$t('my.publishTime')}}：{{ history.startDt | dataFormat }}
            </div>
            <div class="text999 f12">
              {{$t('my.deadline')}}：{{ history.endDt | dataFormat }}
            </div>
          </div>
        </div>
        <!-- 这里的加载中的插件需要去入口文件引入vant组件 -->
        <van-loading type="spinner"
                     color="white"
                     size="40px"
                     style="position: fixed; top: 0; left: 0"
                     v-if="showLoading" />
        <!-- 结果为空 -->
        <div v-if="historyTrainingList.length == 0"
             class="kong positiona">
          <img src="../../assets/images/kong.png"
               alt=""
               width="150" />
          <div class="f15 textc mt15 text999">{{$t('my.noTestLogging')}}~</div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer class="positionf footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data () {
    return {
      list: [1],
      historyTrainingList: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 30,
      showLoading: false,
    };
  },
  methods: {
    //跳转到培训详情
    gotoDetail () {
      this.$router.push({
        path: "/trainingDetail",
      });
    },
    //   跳转到测试记录
    gotoRecord () {
      this.$router.push({
        path: "/testRecord",
      });
    },
    onScroll () {
      console.log("滚动了");
      // 可滚动容器的高度
      var innerHeight = document.querySelector(".BoxHeight2").clientHeight;
      // 屏幕尺寸高度
      var outerHeight = document.documentElement.clientHeight - 70;
      // 可滚动容器超出当前窗口显示范围的高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight <= (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      console.log(
        "可滚动容器的高度:" + innerHeight,
        "屏幕尺寸高度:" + outerHeight,
        "可滚动容器超出当前窗口显示范围的高度:" + scrollTop
      );
      if (outerHeight + scrollTop >= innerHeight) {
        console.log("触底了");
        // 加载更多操作
        this.currPage++;
        this.loadHistoryTrainingList();
      }
    },
    loadHistoryTrainingList () {
      this.showLoading = true;
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      var that = this;
      this.$http
        .post("memberExamRecords/queryTrainRecordPage", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            //console.log(res.data.data.data);
            that.historyTrainingList = res.data.data.data;
            that.currPage = res.data.data.currPage;
            that.totalPage = res.data.data.pageCount;
            that.showLoading = false;
          }
        });
    },
  },
  created () {
    //查询培训记录
    this.loadHistoryTrainingList();
    window.addEventListener("scroll", this.onScroll);
  },
  mounted () {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.item {
  background: #f8f8f8;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 8px 12px;
}
.more {
  width: 68px;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>